<template>
  <div class="manage">
    <!-- 弹窗功能 -->
    <el-dialog
      title="新增"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="表名" prop="table_name">
          <el-select v-model="form.table_name" placeholder="请选择表名" filterable>
            <el-option
              v-for="(item, index) in pageData.select.table"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="src目录" prop="src_path">

          <!-- webkitdirectory代表选择文件夹 -->
          <input type="file" id="file" ref="fileInput" hidden multiple>
          <el-input placeholder="文件夹目录" v-model="form.src_path" class="input-with-select">
            <el-button slot="append" icon="el-icon-folder" type="success"></el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="备注" prop="notes">
          <el-input placeholder="请输入路径" v-model="form.notes"/>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog
      title="表结构"
      :visible.sync="showStructDialog"
      width="80%"
      :before-close="cancelDialog">
      <div class="manage-header">
        <el-button type="primary" size="mini" @click="refreshStructButton(info)">刷新表结构</el-button>
      </div>

      <!-- 用户的表单信息 -->
      <el-table
        :data="info.tableFields"
        height="250"
        border
        style="width: 100%">

        <el-table-column
          prop="column_name"
          label="字段"
          width="150">
        </el-table-column>

        <el-table-column
          prop="query"
          label="列表显示"
          width="80">
          <template v-slot="{ row }">
            <el-switch
              v-model="row.query"
              active-color="#13ce66"
              :active-value="2"
              :inactive-value="1"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="search"
          label="搜索"
          width="200">
          <template v-slot="{ row }">
            <el-select v-model="row.search">
              <el-option label="不搜索" :value="1"/>
              <el-option label="模糊搜索" :value="2"/>
              <el-option label="时间搜索" :value="3"/>
              <el-option label="完全匹配" :value="4"/>
              <el-option label="下拉搜索" :value="5"/>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="edit"
          label="修改"
          width="200">
          <template v-slot="{ row }">
            <el-select v-model="row.edit">
              <el-option label="不修改" :value="1"/>
              <el-option label="输入框" :value="2"/>
              <el-option label="下拉框" :value="3"/>
              <el-option label="图片上传" :value="4"/>
              <el-option label="office上传" :value="5"/>
              <el-option label="视频音频" :value="6"/>
              <el-option label="富文本" :value="7"/>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="required"
          label="非空"
          width="80">
          <template v-slot="{ row }">
            <el-switch
              v-model="row.required"
              active-color="#13ce66"
              :active-value="2"
              :inactive-value="1"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="column_type"
          label="资源"
          width="180">
          <template v-slot="{ row }">

            <el-select v-model="row.site_key" :disabled="row.search !== 5">
              <el-option v-for="(item, index) in pageData.select.site" :key="index" :label="item"
                         :value="item"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="column_comment"
          label="注释">
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="generatingClick">生成 代码</el-button>
        </span>
    </el-dialog>

    <!-- 搜索区域 -->
    <div class="manage-header">
      <el-button type="primary" @click="addClick"> + 新增</el-button>
    </div>

    <!-- 表格 -->
    <el-table
      :data="pageData.list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      v-loading="tableLoad"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="table_name" label="表名"/>
      <el-table-column prop="module_name" label="模块名"/>
      <el-table-column prop="class_name" label="类名"/>
      <el-table-column prop="scene" label="场景">
        <template v-slot="{ row }">
          <el-tag type="info" v-if="row.scene === 1">待生成</el-tag>
          <el-tag type="success" v-else>已生成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button size="mini" type="info" @click="structDialog(row)">表结构</el-button>
          <!--          <el-button size="mini" type="info" @click="codeDetailsDialog(scope.row)">代码内容</el-button>-->
          <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Service from '@/service/System/CreateService'
export default Service
</script>

<style lang="less" scoped>
.manage {
  height: 90%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
