import request from '../../utils/request'

export default class CreateRequest {
  // 获取列表
  async listPost (data) {
    return await request.post('/System/Create/Select', data)
  }

  // 添加操作
  async addPost (data) {
    return request.post('/System/Create/Insert', data)
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/System/Create/Edit', data)
  }

  // 删除操作
  async deletePost (data) {
    return request.post('/System/Create/Delete', data)
  }

  // 刷新表结构
  async refreshStructPost (data) {
    return request.post('/System/Create/RefreshStructPost', data)
  }
}
